const detectChromeiOS = () => {
   var ua = window.navigator.userAgent;
   return ua.match(/crios/i);
};

const isNewiPhone = () => {
   return (
      [1702, 1792, 2356, 2436, 2442, 2532, 2688, 2778].indexOf(
         window.screen.height * window.devicePixelRatio
      ) > -1
   );
};

const isLegacyiPhone = () => {
   return !isNewiPhone();
};

const getiOSVersion = () => {
   if (detectiOS()) {
      var agent = window.navigator.userAgent,
         start = agent.indexOf("OS ");
      if (
         (agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) &&
         start > -1
      ) {
         return window.Number(agent.substr(start + 3, 4).replace("_", "."));
      }
      return null;
   }
   return null;
};

const detectiOS = () => {
   var ua = window.navigator.userAgent;
   // In iOS 13 Apple removed "iPad" from the iPad user-agent... So we need to check for touch points as well
   return (
      (ua.toLowerCase().indexOf("macintosh") > -1 &&
         navigator.maxTouchPoints &&
         navigator.maxTouchPoints > 2) ||
      ua.match(/ipad|iphone|ipod/i)
   );
};

const detectiPhone = () => {
   var ua = window.navigator.userAgent;
   return ua.match(/iphone|ipod/i);
};

const detectiPad = () => {
   var ua = window.navigator.userAgent;
   return (
      (ua.toLowerCase().indexOf("macintosh") > -1 &&
         navigator.maxTouchPoints &&
         navigator.maxTouchPoints > 2) ||
      ua.match(/ipad/i)
   );
};

const inIframe = () => {
   try {
      return window.self !== window.top;
   } catch (e) {
      return true;
   }
};

export {
   detectChromeiOS,
   isNewiPhone,
   isLegacyiPhone,
   getiOSVersion,
   detectiOS,
   detectiPhone,
   detectiPad,
   inIframe,
};
