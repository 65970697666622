<template></template>

<script>
import {
  detectChromeiOS,
  isNewiPhone,
  getiOSVersion,
  detectiOS,
  inIframe,
} from "../helpers/detectiOS";

export default {
  name: "iPhoneSetup",

  data() {
    return {
      oldHeightLandscape:
        this.$store.getters["fullscreen/getOldHeights"].landscape,
      oldHeightPortrait:
        this.$store.getters["fullscreen/getOldHeights"].portrait,
      isStandaloneMode:
        "standalone" in window.navigator && window.navigator.standalone,
    };
  },

  computed: {

    isFullscreenActivated() {
      return this.$store.getters["fullscreen/getFullscreenActivated"];
    },
  },

  mounted() {
    if (
      !window.performance.memory &&
      this.$store.getters["deviceInfo/isIos"] == true &&
      !this.$store.getters["deviceInfo/getIsIpad"] &&
      !this.isStandaloneMode &&
      !this.isFullscreenActivated &&
      getiOSVersion() < 15.4
    ) {
      if (!this.isStandaloneMode) {
        localStorage.setItem(
          "VR_OldHeights",
          JSON.stringify({
            landscape: Number.MAX_SAFE_INTEGER,
            portrait: Number.MAX_SAFE_INTEGER,
          })
        );
      }
      this.setupiPhoneSoftFullscreen();
      this.checkIfFullscreen();
      this.$store.commit("fullscreen/mutateFullscreenActivated", true);
    }
  },

  methods: {
    isIPhoneX() {
      let iPhoneXHeights = [1702, 1792, 2356, 2436, 2442, 2532, 2688, 2778];

      return (
        !window.performance.memory &&
        this.$store.isIos == true &&
        iPhoneXHeights.indexOf(window.screen.height * window.devicePixelRatio) >
          -1
      );
    },

    setupiPhoneSoftFullscreen() {
      window.scrollTo(0, 0);
      if (window.performance.memory) return;

      if (getiOSVersion() >= 15) {
        document.getElementsByTagName("html")[0].style.height = "100vh";
        document.body.style.height = "100vh";
      }

      // this.$store.dispatch("fullscreen/checkOrientation");

      document
        .getElementById("swipeToFullscreen")
        .addEventListener("touchend", this.preventMove, {
          passive: false,
        });
      document
        .getElementById("fullscreenTrick")
        .addEventListener("touchend", this.preventMove);
      document.getElementById("swipeToFullscreen").style.display = "none";
      document.getElementById("swipeIcon").style.display = "none";
      document.getElementById("swipeToFullscreen").style.touchAction = "none";
      document.body.style.overflow = "hidden";
      window.scrollTo(window.scrollX, window.scrollY);

      window.addEventListener("resize", this.checkIfFullscreen);
    },

    preventMove(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.stopPropagation();
      window.scrollTo(0, 0);
    },

    checkIfFullscreen() {
      let oldHeights = JSON.parse(localStorage.getItem("VR_OldHeights"));

      let orientation =
        window.innerHeight > window.innerWidth ? "portrait" : "landscape";

      // came from login, save new window dimensions but do not change the fullscreen state
      if (!oldHeights) {
        if (orientation == "portrait") {
          localStorage.setItem(
            "VR_OldHeights",
            JSON.stringify({
              landscape: Number.MAX_SAFE_INTEGER,
              portrait: window.innerHeight,
            })
          );
        } else {
          localStorage.setItem(
            "VR_OldHeights",
            JSON.stringify({
              landscape: window.innerHeight,
              portrait: Number.MAX_SAFE_INTEGER,
            })
          );
        }
        return;
      }

      // Skip screen rotations
      if (orientation == "landscape") {
        if (window.innerHeight <= oldHeights.landscape) {
          document
            .getElementById("swipeToFullscreen")
            .addEventListener("touchend", this.preventMove, {
              passive: false,
            });
          document
            .getElementById("fullscreenTrick")
            .addEventListener("touchend", this.preventMove);
          document.getElementById("swipeToFullscreen").style.display = "none";
          document.getElementById("swipeIcon").style.display = "none";
          document.getElementById("swipeToFullscreen").style.touchAction =
            "none";
          document.body.style.overflow = "hidden";
          document.getElementsByTagName("html")[0].style.height = "100vh";
          document.body.style.height = "100%";
          // window.scrollTo(window.scrollX, window.scrollY);
        } else {
          document
            .getElementById("swipeToFullscreen")
            .removeEventListener("touchend", this.preventMove);
          document
            .getElementById("fullscreenTrick")
            .removeEventListener("touchend", this.preventMove);
          document.getElementById("swipeToFullscreen").style.display = "block";
          document.getElementById("swipeIcon").style.display = "flex";
          document.getElementById("swipeToFullscreen").style.touchAction = "";
          document.body.style.overflow = "auto";
          // window.scrollTo(0, 0);
        }
        oldHeights.landscape = window.innerHeight;
      } else {
        if (window.innerHeight >= oldHeights.portrait) {
          document
            .getElementById("swipeToFullscreen")
            .addEventListener("touchend", this.preventMove, {
              passive: false,
            });
          document
            .getElementById("fullscreenTrick")
            .addEventListener("touchend", this.preventMove);
          document.getElementById("swipeToFullscreen").style.display = "none";
          document.getElementById("swipeIcon").style.display = "none";
          document.getElementById("swipeToFullscreen").style.touchAction =
            "none";
          document.body.style.overflow = "hidden";
          document.getElementsByTagName("html")[0].style.height = "100vh";
          setTimeout(() => {
            document.getElementById("fullscreenTrick").style.display = "none";
            // document.body.style.height = "100vh";
          }, 0);
        } else {
          document
            .getElementById("swipeToFullscreen")
            .removeEventListener("touchend", this.preventMove);
          document
            .getElementById("fullscreenTrick")
            .removeEventListener("touchend", this.preventMove);
          document.getElementById("swipeToFullscreen").style.display = "block";
          document.getElementById("swipeIcon").style.display = "flex";
          document.getElementById("swipeToFullscreen").style.touchAction = "";
          document.body.style.overflow = "auto";
          setTimeout(() => {
            document.getElementsByTagName("html")[0].style.height = "100vh";
            document.body.style.height = "100%";
            setTimeout(() => {
              document.getElementById("fullscreenTrick").style.display =
                "block";
            }, 0);
          }, 0);
        }
        oldHeights.portrait = window.innerHeight;
      }
      localStorage.setItem("VR_OldHeights", JSON.stringify(oldHeights));
    },
  },

  unmounted() {
    this.$store.commit("fullscreen/mutateFullscreenActivated", false);
    window.removeEventListener("resize", this.checkIfFullscreen);
  },
};
</script>

<style lang="postcss">
#swipeToFullscreen {
  top: -50px;
  height: 250vh;
  z-index: 696969;
  background: white;
  @apply hidden absolute w-full select-none opacity-75;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-overflow-scrolling: touch;
}

#swipeIcon {
  z-index: 696970;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-overflow-scrolling: touch;
  @apply hidden w-full h-full fixed items-center justify-center;
}

@media screen and (orientation: portrait) {
  #swipeIcon > img {
    padding-top: 40vh;
    width: 80%;
  }
}

@media screen and (orientation: landscape) {
  #swipeIcon > img {
    padding-top: 32.5vh;
    width: 35%;
  }

  #swipeToFullscreen {
    height: 500vh !important;
  }

  #fullscreenTrick {
    height: 300vh !important;
  }
}

#fullscreenTrick {
  height: 150vh;
  z-index: -100000;
  @apply overflow-hidden;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
  touch-action: none;
}
</style>
